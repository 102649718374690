import { SizeVariant, StyleVariant } from "model/themeVariant";
import { getThemeStyleColor } from "model/themeUtils";
import {
  createStyle,
  FlexAlignItems,
  flexColumnMixin,
  flexItemMixin,
  FlexJustifyContent,
  flexRowMixin,
  getClassName,
  getZIndexValue,
  marginMixin,
  paddingMixin,
  pxToRem,
  ZIndexVariant
} from "@laba/react-common";
import { Theme, ThemeStyleColorVariant } from "model/theme";
import { ClassNameDynamicGeneratedRecord } from "components/utils/getGenericButtonColors";

export enum PopupVariant {
  Horizontal = "Horizontal",
  Vertical = "Vertical"
}

export enum ButtonsPopupVariant {
  Horizontal = "Horizontal",
  Vertical = "Vertical"
}

export type PopupSizeVariant = SizeVariant.Large | SizeVariant.Small;

export interface BasePopupStyleProps {
  size: PopupSizeVariant;
  variant: PopupVariant;
  fullWidth: boolean;
  loading?: boolean;
  dropMargins?: boolean;
  withStickyFooterButtons?: boolean;
  withCommercialBackground?: boolean;
  hasChildren?: boolean;
}

interface MuiBasePopupStyleClassNames {
  paperWidthSm?: string;
  root?: string;
}

const generatedStyleKeyTemplate = (
  variant: PopupVariant,
  size: SizeVariant,
  fullWidth: boolean
) => `generatedStyle${variant}${size}${fullWidth}`;

const generateDynamicStylesClassNames = (): ClassNameDynamicGeneratedRecord => {
  const classNameObject: ClassNameDynamicGeneratedRecord = {};
  Object.values([SizeVariant.Large, SizeVariant.Small]).forEach(sizeVariant => {
    Object.values(PopupVariant).forEach(popupVariant => {
      Object.values([true, false]).forEach(fullWidth => {
        classNameObject[
          generatedStyleKeyTemplate(popupVariant, sizeVariant, fullWidth)
        ] = {
          width: fullWidth ? "100%" : "",
          height: fullWidth ? "100%" : "",
          maxHeight: fullWidth ? "100%" : "",
          ...marginMixin({
            all: fullWidth ? 0 : ""
          }),
          maxWidth: fullWidth
            ? "100%"
            : popupVariant === PopupVariant.Horizontal
            ? sizeVariant === SizeVariant.Large
              ? pxToRem(780)
              : pxToRem(560)
            : pxToRem(280)
        };
      });
    });
  });
  return classNameObject;
};

const useMuiBasePopupStyle = createStyle(
  (_theme: Theme) => ({
    ...generateDynamicStylesClassNames()
  }),
  "MuiBasePopup"
);

const useMuiBasePopupStaticStyle = createStyle(
  (_theme: Theme) => ({
    root: {
      // En realidad el zIndex de Dialog se maneja inline, por lo que esto no tiene efecto. Lo dejo para
      // que quede explícito el valor que tiene como referencia
      zIndex: getZIndexValue(ZIndexVariant.PopupUpElevation)
    }
  }),
  "MuiBaseStaticPopup"
);

export const useMuiBasePopupStyleClassNames = (
  props: BasePopupStyleProps
): MuiBasePopupStyleClassNames => {
  const classes = useMuiBasePopupStyle();
  const staticClasses = useMuiBasePopupStaticStyle();

  return {
    root: staticClasses.root,
    paperWidthSm:
      classes[
        generatedStyleKeyTemplate(props.variant, props.size, props.fullWidth)
      ]
  };
};

interface BasePopupClassNames {
  dialogContent: string;
  loadingScreen: string;
  paper: string;
  popupHeader: string;
  footerButtons: string;
  deleteButton: string;
  secondaryButton: string;
  scrollableContainer: string;
}

const useBasePopupStaticStyles = createStyle(
  (theme: Theme) => ({
    horizontalButtonsContainer: {
      ...flexRowMixin({ reverseOrder: true }),
      ...marginMixin({
        left: 32,
        right: 32,
        bottom: 32
      }),
      columnGap: pxToRem(8)
    },
    verticalButtonsContainer: {
      ...flexColumnMixin({}),
      ...marginMixin({
        leftRight: 24,
        bottom: 24
      }),
      rowGap: pxToRem(8)
    },
    verticalMobileButtonsContainer: {
      ...flexColumnMixin({}),
      ...marginMixin({
        leftRight: 24,
        bottom: 32
      }),
      rowGap: pxToRem(16)
    },
    horizontalButtonTopMargin: {
      ...marginMixin({ top: 32 })
    },
    verticalButtonTopMargin: { ...marginMixin({ top: 24 }) },
    cancelButtonHorizontal: {
      ...flexItemMixin({
        order: 0
      })
    },
    cancelButtonVertical: {
      ...flexItemMixin({
        order: 0
      })
    },
    deleteButtonHorizontal: {
      ...flexItemMixin({
        order: 0
      }),
      marginRight: "auto"
    },
    deleteButtonVertical: {
      ...flexItemMixin({
        order: 0
      })
    },
    dialogContent: {
      position: "relative",
      overflow: "visible"
    },
    horizontalContentMargins: {
      ...marginMixin({ leftRight: 24, bottom: 32, top: 20 })
    },
    verticalContentMargins: {
      ...marginMixin({ all: 24 })
    },
    noBottomMargin: { ...marginMixin({ bottom: 0 }) },
    scrollableContainerMobile: {
      ...flexColumnMixin({})
    },
    scrollableContainerDesktop: {
      ...flexColumnMixin({ justifyContent: FlexJustifyContent.SpaceBetween })
    },
    dialogContentLoading: {
      overflow: "hidden",
      maxHeight: "100%"
    },
    dialogContentLoadingFixedHeight: { height: pxToRem(200) },
    loadingScreen: {
      ...flexRowMixin({
        alignItems: FlexAlignItems.Center,
        justifyContent: FlexJustifyContent.Center
      }),
      backgroundColor: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.Contrast
      ),
      height: "100%",
      left: 0,
      position: "absolute",
      top: 0,
      width: "100%",
      zIndex: 2
    },
    paper: {
      width: "100%",
      minHeight: pxToRem(64)
    },
    commercialBackgroundColor: {
      backgroundColor: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.Background
      )
    },
    popupHeader: {
      position: "sticky",
      top: 0,
      zIndex: getZIndexValue(ZIndexVariant.HeaderElevation),
      backgroundColor: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.Contrast
      )
    },
    dropMargins: {
      ...marginMixin({ all: 0 })
    }
  }),
  "BasePopupStatic"
);

export const useBasePopupStyle = (
  props: BasePopupStyleProps
): BasePopupClassNames => {
  const staticClasses = useBasePopupStaticStyles();

  const {
    fullWidth,
    variant,
    loading,
    dropMargins,
    withStickyFooterButtons,
    hasChildren
  } = props;

  return {
    footerButtons: getClassName(
      fullWidth
        ? staticClasses.verticalMobileButtonsContainer
        : variant === PopupVariant.Horizontal
        ? staticClasses.horizontalButtonsContainer
        : staticClasses.verticalButtonsContainer,
      withStickyFooterButtons
        ? variant === PopupVariant.Horizontal
          ? staticClasses.horizontalButtonTopMargin
          : staticClasses.verticalButtonTopMargin
        : undefined
    ),
    deleteButton:
      variant === PopupVariant.Horizontal && !fullWidth
        ? staticClasses.deleteButtonHorizontal
        : staticClasses.deleteButtonVertical,
    secondaryButton:
      variant === PopupVariant.Horizontal && !fullWidth
        ? staticClasses.cancelButtonHorizontal
        : staticClasses.cancelButtonVertical,
    scrollableContainer: fullWidth
      ? staticClasses.scrollableContainerMobile
      : staticClasses.scrollableContainerDesktop,
    dialogContent: getClassName(
      staticClasses.dialogContent,
      loading ? staticClasses.dialogContentLoading : undefined,
      hasChildren ? staticClasses.dialogContentLoadingFixedHeight : undefined,
      dropMargins
        ? staticClasses.dropMargins
        : variant === PopupVariant.Horizontal
        ? staticClasses.horizontalContentMargins
        : staticClasses.verticalContentMargins,
      withStickyFooterButtons ? staticClasses.noBottomMargin : undefined
    ),
    loadingScreen: staticClasses.loadingScreen,
    paper: getClassName(
      staticClasses.paper,
      props.withCommercialBackground
        ? staticClasses.commercialBackgroundColor
        : undefined
    ),
    popupHeader: staticClasses.popupHeader
  };
};

export const useDialogContentStyles = createStyle(
  (_theme: Theme) => ({
    root: {
      ...paddingMixin({ all: 0 }),
      ...flexItemMixin({ shrink: 0 }),
      "&:first-child": {
        ...paddingMixin({ top: 0 })
      }
    }
  }),
  "DialogContent"
);
