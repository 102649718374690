import { EncounterStatus } from "model/resource";

export const getHospitalizationOrderValidTransitionStatus = (
  status?: EncounterStatus
): EncounterStatus[] => {
  switch (status) {
    case EncounterStatus.InProgress:
      return [
        EncounterStatus.InProgress,
        EncounterStatus.Finished,
        EncounterStatus.Cancelled
      ];
    case EncounterStatus.Planned:
      return [
        EncounterStatus.Planned,
        EncounterStatus.InProgress,
        EncounterStatus.Arrived,
        EncounterStatus.Finished,
        EncounterStatus.Cancelled
      ];
    case EncounterStatus.Finished:
      return [EncounterStatus.Finished, EncounterStatus.Cancelled];
    case EncounterStatus.Cancelled:
      return [EncounterStatus.Cancelled];

    case EncounterStatus.Triaged:
      return [];
    case EncounterStatus.Arrived:
      return [];
    default:
      return [
        EncounterStatus.Planned,
        EncounterStatus.InProgress,
        EncounterStatus.Arrived,
        EncounterStatus.Finished,
        EncounterStatus.Cancelled
      ];
  }
};
