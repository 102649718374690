import { Code } from "model/resource";
import { getKeyObj, ModelId } from "@laba/ts-common";
import { createHydratedMock } from "ts-auto-mock";

export enum PayerConfigurationType {
  OnlyPrivatePatient = "OnlyPrivatePatient",
  OnlyPayer = "OnlyPayer"
}

export interface PayerPlanConfiguration {
  plan?: Code;
}

export interface PayerConfiguration {
  payer?: ModelId;
  planConfiguration?: PayerPlanConfiguration[];
}

export interface PayerRulesConfiguration {
  type?: PayerConfigurationType;
  payerConfiguration?: PayerConfiguration[];
}
export const PayerRulesConfigurationParamsKey =
  getKeyObj<PayerRulesConfiguration>(
    createHydratedMock<PayerRulesConfiguration>()
  );

export const PayerPlanConfigurationParamsKey =
  getKeyObj<PayerPlanConfiguration>(
    createHydratedMock<PayerPlanConfiguration>()
  );
