import React from "react";
import {
  FCC,
  getWhatsappPhoneChatLink,
  TextVariant,
  ThemeStyleColorVariant
} from "@laba/react-common";
import { LinkFieldDetail } from "components/linkFieldDetail/LinkFieldDetail";
import { WhatsAppIcon } from "components/icons";
import { UrlLinkIconSize } from "components/link";

export interface OpenWhatsappChatProps {
  title?: string;
  phoneText?: string;
  gap?: number;
  className?: string;
  hide?: boolean;
  titleVariant?: TextVariant;
  textVariant?: TextVariant;
  titleColorVariant?: ThemeStyleColorVariant;
  showIcon?: boolean;
  iconSize?: UrlLinkIconSize;
  centerText?: boolean;
}

export const OpenWhatsappChat: FCC<OpenWhatsappChatProps> = ({
  phoneText,
  showIcon,
  ...rest
}) => {
  const whatsappUrl = phoneText
    ? getWhatsappPhoneChatLink(phoneText)
    : undefined;

  return (
    <LinkFieldDetail
      displayText={phoneText}
      url={whatsappUrl}
      {...rest}
      StartIcon={WhatsAppIcon}
      withStartIcon={showIcon}
    />
  );
};
