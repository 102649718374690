import { useState } from "react";
import { Noop } from "@laba/ts-common";

export enum OnClickUrlAction {
  CopyToClipboard = "CopyToClipboard",
  Redirect = "Redirect",
  CustomClick = "CustomClick"
}

interface UseUrlLinkProps {
  snackbarText?: string;
  url?: string;
  onClickUrlAction: OnClickUrlAction;
  onClick?: Noop;
  onClickGetClipboardLink?: () => Promise<string>;
}

interface UseUrlLinkReturn {
  isSnackBarOpen: boolean;
  wrappedOnClick: Noop;
  closeSnackbar: Noop;
}

export const useUrlLinkProps = ({
  onClick,
  snackbarText,
  url,
  onClickUrlAction,
  onClickGetClipboardLink
}: UseUrlLinkProps): UseUrlLinkReturn => {
  const [isSnackBarOpen, setIsSnackBarOpen] = useState(false);
  const copyUrlToClipboard = (dynamicText?: string) => {
    if (dynamicText) {
      return navigator.clipboard.writeText(dynamicText);
    }
    url && navigator.clipboard.writeText(url);
  };
  const wrappedOnClick = async () => {
    switch (onClickUrlAction) {
      case OnClickUrlAction.Redirect:
        window.open(url);
        break;
      case OnClickUrlAction.CopyToClipboard: {
        const text = await onClickGetClipboardLink?.();
        await copyUrlToClipboard(text);
        snackbarText && setIsSnackBarOpen(true);
        break;
      }
      case OnClickUrlAction.CustomClick:
        onClick?.();
        break;
    }
  };

  return {
    isSnackBarOpen,
    closeSnackbar: () => setIsSnackBarOpen(false),
    wrappedOnClick
  };
};

export const useShareUrlLink = (url?: string): (() => Promise<void>) => {
  return async () => {
    if (url) await navigator.share({ text: url });
  };
};
