import { ModelId } from "model/primitives";
import {
  Code,
  KnownScheduleType,
  PublicScheduleAvailabilityCalendar,
  PublicScheduleAvailabilityCalendarFilterOptions
} from "model/resource";
import { ApiDate, HttpMethod, OpenCode } from "@laba/ts-common";
import {
  getFrontPatientPortalPublicApiUrl,
  request
} from "request/nexupRequest";
import { ApiRequestResponse } from "request/types";
import { scheduleBasePath } from "api/schedule";

interface PatientPublicScheduleAvailabilityCalendarFilterOptionsQueryParams {
  organization: ModelId;
}

interface PatientPublicScheduleAvailabilityCalendarQueryParams {
  organization: ModelId;
  endDate?: ApiDate;
  location?: ModelId[];
  onlyFirstAvailability?: boolean;
  payer?: ModelId;
  payerPlan?: string;
  practitioner?: ModelId[];
  schedule?: ModelId[];
  speciality?: Code[];
  startDate?: ApiDate;
  type?: OpenCode<KnownScheduleType>[];
  withSchedule?: boolean;
}

export const getPatientPublicScheduleAvailabilityCalendarFilterOptions = (
  params: PatientPublicScheduleAvailabilityCalendarFilterOptionsQueryParams
): Promise<
  ApiRequestResponse<PublicScheduleAvailabilityCalendarFilterOptions>
> =>
  request({
    method: HttpMethod.GET,
    url: getFrontPatientPortalPublicApiUrl(
      `${scheduleBasePath}/availability/filter-options`
    ),
    params
  });

export const getPatientPublicScheduleAvailabilityCalendar = (
  params: PatientPublicScheduleAvailabilityCalendarQueryParams
): Promise<ApiRequestResponse<PublicScheduleAvailabilityCalendar>> =>
  request({
    method: HttpMethod.GET,
    url: getFrontPatientPortalPublicApiUrl(`${scheduleBasePath}/availability`),
    params
  });
