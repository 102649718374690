import { PublicPatientData } from "model/resource";
import {
  getFrontPatientPortalPublicApiUrl,
  request
} from "request/nexupRequest";
import { ApiRequestResponse } from "request/types";
import { getKeyObj, HttpMethod, ModelId } from "@laba/ts-common";
import { createHydratedMock } from "ts-auto-mock";

export interface PatientPublicIdentifierData {
  identifierType: string;
  identifierValue: string;
}

export const PatientPublicIdentifierDataKey =
  getKeyObj<PatientPublicIdentifierData>(
    createHydratedMock<PatientPublicIdentifierData>()
  );

interface PatientPublicDataQueryParams extends PatientPublicIdentifierData {
  organization: ModelId;
  withOrganization?: boolean;
  withPayer?: boolean;
}

export const getPatientPublicData = (
  params: PatientPublicDataQueryParams
): Promise<ApiRequestResponse<PublicPatientData>> =>
  request({
    method: HttpMethod.GET,
    url: getFrontPatientPortalPublicApiUrl(`/public-data`),
    params
  });
