import { ThemeProvider } from "@material-ui/core/styles";
import { Children, FC, ReactElement, useTheme } from "@laba/react-common";
import React from "react";
import { StyleVariant } from "model/themeVariant";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import LuxonUtils from "@date-io/luxon";
import { currentLocale, DateTime, isSameDay } from "@laba/ts-common";
import {
  BadgedComponent,
  BadgePosition
} from "components/containers/BadgedComponent/BadgedComponent";
import { isNil, some } from "lodash-es";
import { Theme } from "model/theme";
import { useMuiTheme } from "model/useMuiTheme";
import { useCalendarStyleOverrides } from "./CalendarStyle";

export interface CalendarProps {
  style?: StyleVariant;
  className?: string;
  value?: DateTime;
  onChange: (d?: DateTime) => void;
  maxDate?: DateTime;
  minDate?: DateTime;
  badgedDays?: DateTime[];
  onMonthChange?: (newMonth: DateTime) => void;
  shouldDisableDate?: (day?: DateTime) => boolean;
}

interface DayComponentProps {
  children?: Children;
  current?: boolean;
  disabled?: boolean;
  hidden?: boolean;
  selected?: boolean;
}

export const Calendar: FC<CalendarProps> = ({
  className,
  value,
  onChange,
  maxDate,
  minDate,
  badgedDays,
  style = StyleVariant.Primary,
  onMonthChange,
  shouldDisableDate
}) => {
  const theme = useTheme<Theme>();
  const overrides = useCalendarStyleOverrides(theme);
  const muiTheme = useMuiTheme(style, overrides);

  const renderDayWrapper = (
    dayComponent: ReactElement,
    disableDate?: (day?: DateTime) => boolean,
    day?: DateTime,
    _selectedDate?: DateTime,
    _dayInCurrentMonth?: boolean
  ) => {
    const isBadged = some(badgedDays, bd => isSameDay(day, bd));

    const dayComponentWithDisabledLogic = React.cloneElement<DayComponentProps>(
      dayComponent,
      {
        disabled: disableDate?.(day)
      }
    );

    return (
      <BadgedComponent
        position={BadgePosition.BottomRight}
        style={StyleVariant.Success}
        isVisible={isBadged}
      >
        {dayComponentWithDisabledLogic}
      </BadgedComponent>
    );
  };

  return (
    <ThemeProvider theme={muiTheme}>
      <MuiPickersUtilsProvider locale={currentLocale} utils={LuxonUtils}>
        <DatePicker
          className={className}
          variant="static"
          renderDay={(day, selectedDate, dayInCurrentMonth, dayComponent) => {
            return renderDayWrapper(
              dayComponent,
              shouldDisableDate,
              day ?? undefined,
              selectedDate ?? undefined,
              dayInCurrentMonth
            );
          }}
          onChange={newValue => {
            const newValueOrUndefined = newValue ?? undefined;
            if (shouldDisableDate && shouldDisableDate(newValueOrUndefined))
              return;
            onChange(newValueOrUndefined);
          }}
          value={value ?? undefined}
          disableToolbar
          maxDate={maxDate}
          minDate={minDate}
          onMonthChange={date => {
            if (onMonthChange && !isNil(date)) onMonthChange(date);
          }}
        />
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
};
