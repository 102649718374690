import React, { useEffect, useState } from "react";
import { FC, IconC, StyleVariant, TypeVariant } from "@laba/react-common";
import { ContentCopyIcon, ShareIcon, LinkIcon } from "components/icons";
import { OnClickUrlAction } from "components/link";
import { IconOrDefaultButton } from "components/buttons/IconOrDefaultButton/IconOrDefaultButton";
import { TypeInvertedVariant } from "components/utils/getGenericButtonColors";
import { Snackbar } from "components/snackbar/Snackbar/Snackbar";
import { BaseIconButton } from "components/buttons/BaseIconButton/BaseIconButton";
import { useUrlLinkProps } from "components/link/UrlLink/utils";

export enum ShareButtonType {
  Icon = "Icon",
  BaseIcon = "BaseIcon",
  Default = "Default"
}

export interface ShareButtonProps {
  onClick: (hideSuccessMsg?: boolean) => Promise<string>;
  snackbarText?: string;
  className?: string;
  getTextToShare: (link?: string) => string;
  shareButtonText?: string;
  copyLinkButtonText?: string;
  disabled?: boolean;
  withNativeShare?: boolean;
  style?: StyleVariant;
  StartIcon?: IconC;
  type?: TypeVariant;
  iconType?: TypeInvertedVariant;
  title?: string;
  buttonType?: ShareButtonType;
}

export const ShareButton: FC<ShareButtonProps> = ({
  onClick,
  className,
  getTextToShare,
  shareButtonText,
  copyLinkButtonText,
  snackbarText,
  disabled,
  withNativeShare,
  style,
  StartIcon,
  type = TypeVariant.Outlined,
  iconType,
  title,
  buttonType = ShareButtonType.Default
}) => {
  const [isNativeShareAvailable, setIsNativeShareAvailable] = useState(false);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (navigator.share !== undefined) {
      setIsNativeShareAvailable(true);
    }
  }, []);

  const isShareButton = withNativeShare && isNativeShareAvailable;

  const { closeSnackbar, wrappedOnClick, isSnackBarOpen } = useUrlLinkProps({
    onClick,
    onClickUrlAction: OnClickUrlAction.CopyToClipboard,
    snackbarText,
    url: getTextToShare(),
    onClickGetClipboardLink: onClick
  });

  const onClickWrapper = async () => {
    if (isShareButton) {
      const link = await onClick(true);
      const textToShare = getTextToShare(link);
      await navigator.share({ text: textToShare });
    } else {
      wrappedOnClick();
    }
  };

  return (
    <>
      {buttonType === ShareButtonType.BaseIcon ? (
        <BaseIconButton
          disabled={disabled}
          style={style}
          className={className}
          withoutPadding
          Icon={StartIcon ?? isShareButton ? ShareIcon : LinkIcon}
          onClick={onClickWrapper}
          title={title}
        />
      ) : (
        <IconOrDefaultButton
          className={className}
          onClick={onClickWrapper}
          icon={StartIcon ?? isShareButton ? ShareIcon : ContentCopyIcon}
          text={isShareButton ? shareButtonText : copyLinkButtonText}
          type={type}
          disabled={disabled}
          style={style}
          iconType={iconType}
          isIconButton={buttonType === ShareButtonType.Icon}
          title={title}
        />
      )}
      <Snackbar
        isOpen={isSnackBarOpen}
        style={StyleVariant.Success}
        text={snackbarText}
        onClose={closeSnackbar}
      />
    </>
  );
};
