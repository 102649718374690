import {
  ModelId,
  CountryAreaPhoneCode,
  getKeyObj,
  StyleVariant,
  Theme,
  TypeVariant
} from "@laba/ts-common";
import { createHydratedMock } from "ts-auto-mock";
import { ModelReference } from "model/primitives/modelReference/modelReference";
import { Organization } from "model/resource/entities/organization/organization";
import { PatientPermissionConfig } from "model/resource";

export enum HomeItemType {
  Whitespace = "Whitespace",
  Text = "Text",
  Button = "Button"
}

export enum HomeItemPropertyType {
  NumRows = "NumRows",
  NumColumns = "NumColumns",
  Title = "Title",
  Text = "Text",
  Icon = "Icon",
  ButtonVariant = "ButtonVariant",
  ClickAction = "ClickAction",
  Value = "Value",
  EmailSubject = "EmailSubject",
  EmailBody = "EmailBody"
}

export interface WhiteLabelBannerItemConfig {
  itemUrl?: string;
  isVideo?: boolean;
  title?: string;
  principalText?: string;
  secondaryText?: string;
  buttonText?: string;
  buttonUrl?: string;
  buttonType?: TypeVariant;
  buttonStyle?: StyleVariant;
}

export const WhiteLabelBannerItemConfigKey =
  getKeyObj<WhiteLabelBannerItemConfig>(
    createHydratedMock<WhiteLabelBannerItemConfig>()
  );

export interface BaseHomeItemProperty<P> {
  type: P;
  value?: string;
}

export const BaseHomeItemPropertyKey = getKeyObj<BaseHomeItemProperty<string>>(
  createHydratedMock<BaseHomeItemProperty<string>>()
);

export interface BaseHomeItem<
  T extends HomeItemType,
  P extends HomeItemPropertyType = HomeItemPropertyType
> {
  type: T;
  property: BaseHomeItemProperty<P>[];
}

export const BaseHomeItemKey = getKeyObj<BaseHomeItem<HomeItemType>>(
  createHydratedMock<BaseHomeItem<HomeItemType>>()
);

type WhitespacePropertyType =
  | HomeItemPropertyType.NumRows
  | HomeItemPropertyType.NumColumns;

export type WhitespaceItem = BaseHomeItem<
  HomeItemType.Whitespace,
  WhitespacePropertyType
>;

type TextPropertyType =
  | HomeItemPropertyType.Text
  | HomeItemPropertyType.NumRows
  | HomeItemPropertyType.NumColumns;

export type TextItem = BaseHomeItem<HomeItemType.Text, TextPropertyType>;

type ButtonPropertyType =
  | HomeItemPropertyType.Icon
  | HomeItemPropertyType.Title
  | HomeItemPropertyType.Text
  | HomeItemPropertyType.ButtonVariant
  | HomeItemPropertyType.ClickAction
  | HomeItemPropertyType.Value
  | HomeItemPropertyType.EmailSubject
  | HomeItemPropertyType.EmailBody
  | HomeItemPropertyType.NumColumns
  | HomeItemPropertyType.NumRows;

export type ButtonItem = BaseHomeItem<HomeItemType.Button, ButtonPropertyType>;

export type WhiteLabelHomeItem = WhitespaceItem | TextItem | ButtonItem;

export interface WhiteLabelHomeConfig {
  bannerItemConfigList: WhiteLabelBannerItemConfig[];
  itemList: WhiteLabelHomeItem[];
  numColumns?: number;
}

export interface WhiteLabelBrandData {
  name?: string;
  latitude?: number;
  longitude?: number;
  loginTitle?: string;
  loginText?: string;
  loginIcon?: string;
  browserTitle?: string;
  browserIcon?: string;
  headerIcon?: string;
  shareTitle?: string;
  shareDescription?: string;
  shareImage?: string;
  shareUrl?: string;
  installName?: string;
  installShortName?: string;
  installDescription?: string;
  installBackground?: string;
  installIconSmall?: string;
  installIconBig?: string;
  metadataTitle?: string;
  metadataDescription?: string;
  showInstallAppBanner?: boolean;
  showInstallAppBannerText?: string;
  disableLogin?: boolean;
  rightHeaderIcon?: string;
  rightHeaderIconUrl?: string;
  domainUrl?: string;
}

export interface WhiteLabelCredentialData {
  color?: string;
  logo?: string;
  icon?: string;
}

export interface WhiteLabelDirectoryData {
  enablePlanFilter?: boolean;
  enableSpecialityFilter?: boolean;
  enableCityFilter?: boolean;
  enableMap?: boolean;
  title?: string;
}

export interface WhiteLabelCountryPhone {
  defaultAreaCode?: CountryAreaPhoneCode;
  excludeCountries?: CountryAreaPhoneCode[];
  preferredCountries?: CountryAreaPhoneCode[];
}
export interface WhiteLabelConfig extends PatientPermissionConfig {
  organizationData?: {
    maxDateInterval?: number;
    drawerButtonText?: string;
    helpButtonUrl?: string;
    countryPhone?: WhiteLabelCountryPhone;
  };
  organization?: ModelId;
  hisBrandData?: WhiteLabelBrandData;
  patientBrandData?: WhiteLabelBrandData;
  brandData?: WhiteLabelBrandData;
  credentialData?: WhiteLabelCredentialData;
  desktopHomeConfig?: WhiteLabelHomeConfig;
  mobileHomeConfig?: WhiteLabelHomeConfig;
  directoryConfig?: WhiteLabelDirectoryData;
  theme?: Theme;
  templateOrganizationId?: ModelReference<Organization>;
}

export const WhiteLabelConfigKey = getKeyObj<WhiteLabelConfig>(
  createHydratedMock<WhiteLabelConfig>()
);
export const WhiteLabelConfigBrandDataKey = getKeyObj<WhiteLabelBrandData>(
  createHydratedMock<WhiteLabelBrandData>()
);

export enum WhiteLabelButtonVariant {
  Vertical = "Vertical",
  Horizontal = "Horizontal",
  Centered = "Centered"
}

export enum WhiteLabelClickAction {
  Redirect = "Redirect",
  Call = "Call",
  Email = "Email"
}
