import { IconC, ReactElement, getClassName, FCC } from "@laba/react-common";
import React from "react";
import { StyleVariant } from "model/themeVariant";
import { Noop } from "@laba/ts-common";
import {
  DialogSizeVariant,
  useDialogStyles
} from "components/popup/Dialog/DialogStyle";
import { BasePopup } from "../BasePopup/BasePopup";
import { PopupVariant } from "../BasePopup/BasePopupStyle";

export interface DialogProps {
  className?: string;
  disableBackdropClick?: boolean;
  textTitle?: string;
  textSubtitle?: string | ReactElement;
  onClose?: Noop;
  open?: boolean;
  style?: StyleVariant;
  primaryButtonStyle?: StyleVariant;
  iconStyle?: StyleVariant;
  primaryButtonText?: string;
  secondaryButtonText?: string;
  onPrimaryButtonClick?: Noop;
  onSecondaryButtonClick?: Noop;
  PrimaryButtonIcon?: IconC;
  SecondaryButtonIcon?: IconC;
  imageSource?: string;
  ImageSvg?: IconC;
  IconTitle?: IconC;
  dialogSizeVariant?: DialogSizeVariant;
  loading?: boolean;
  isMobile?: boolean;
  disablePrimaryButton?: boolean;
}

export const Dialog: FCC<DialogProps> = ({
  className,
  onClose,
  onPrimaryButtonClick,
  onSecondaryButtonClick,
  primaryButtonText,
  secondaryButtonText,
  PrimaryButtonIcon,
  SecondaryButtonIcon,
  textTitle,
  textSubtitle,
  imageSource,
  ImageSvg,
  IconTitle,
  disableBackdropClick = false,
  open = false,
  style = StyleVariant.Primary,
  primaryButtonStyle,
  iconStyle,
  dialogSizeVariant,
  loading,
  isMobile,
  children,
  disablePrimaryButton
}) => {
  const hasImageUrl = Boolean(imageSource);
  const hasImageSvg = Boolean(ImageSvg) && !hasImageUrl;
  const hasImage = hasImageSvg || hasImageUrl;
  const hasIcon = Boolean(IconTitle) && !hasImage;
  const dialogVariant = isMobile
    ? PopupVariant.Vertical
    : PopupVariant.Horizontal;
  const classes = useDialogStyles({ iconStyle, dialogVariant, isMobile });

  return (
    <BasePopup
      className={className}
      open={open}
      style={style}
      primaryButtonStyle={primaryButtonStyle}
      onClose={onClose}
      onPrimaryButtonClick={onPrimaryButtonClick}
      onSecondaryButtonClick={onSecondaryButtonClick}
      disableBackdropClick={disableBackdropClick}
      primaryButtonText={primaryButtonText}
      secondaryButtonText={secondaryButtonText}
      PrimaryButtonIcon={PrimaryButtonIcon}
      SecondaryButtonIcon={SecondaryButtonIcon}
      popupVariant={dialogVariant}
      popupSizeVariant={dialogSizeVariant}
      fullWidth={false}
      loading={loading}
      disablePrimaryButton={disablePrimaryButton}
    >
      <div className={getClassName(classes.grayDarkColor, classes.content)}>
        <div className={classes.titleContainer}>
          {hasImageUrl && (
            <img src={imageSource} alt={imageSource} className={classes.img} />
          )}
          {hasImageSvg && ImageSvg && <ImageSvg className={classes.imageSvg} />}
          {hasIcon && IconTitle && <IconTitle className={classes.icon} />}
          <p className={classes.textTitle}>{textTitle}</p>
        </div>
        {typeof textSubtitle === "string" ? (
          <p className={classes.textSubtitle}>{textSubtitle}</p>
        ) : (
          textSubtitle
        )}
        {children}
      </div>
    </BasePopup>
  );
};
