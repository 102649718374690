import { Optional } from "model/types";

export enum ZIndexVariant {
  PopupUpElevation = "PopupUpElevation",
  HeaderElevation = "HeaderElevation",
  ListItemElevation = "ListItemElevation",
  PopoverElevation = "PopoverElevation",
  TagElevation = "TagElevation",
  RegularElevation = "RegularElevation",
  RemoteVideoFeed = "RemoteVideoFeed",
  LocalVideoFeed = "LocalVideoFeed",
  VideoControls = "VideoControls",
  OverVideoControls = "OverVideoControls",
  MinimizedVideoCall = "MinimizedVideoCall"
}

export const getZIndexValue = (
  zIndexVariant: ZIndexVariant
): Optional<number> => {
  switch (zIndexVariant) {
    case ZIndexVariant.HeaderElevation:
      return 5;
    case ZIndexVariant.RegularElevation:
      return 4;
    case ZIndexVariant.PopupUpElevation:
      return 1300;
    case ZIndexVariant.ListItemElevation:
      return 0;
    case ZIndexVariant.PopoverElevation:
      return 100000;
    case ZIndexVariant.MinimizedVideoCall:
      return 99;
    case ZIndexVariant.RemoteVideoFeed:
      return 100;
    case ZIndexVariant.LocalVideoFeed:
    case ZIndexVariant.VideoControls:
      return 101;
    case ZIndexVariant.OverVideoControls:
      return 102;
    default:
      return undefined;
  }
};
