import {
  borderMixin,
  StyleVariant,
  Theme,
  ThemeStyleColorVariant
} from "@laba/ts-common";
import { getThemeStyleColor } from "model/themeUtils";

export const useCalendarStyleOverrides = (
  theme: Theme
): Record<string, unknown> => {
  return {
    MuiPickersCalendarHeader: {
      switchHeader: {
        color: getThemeStyleColor(
          theme,
          StyleVariant.Primary,
          ThemeStyleColorVariant.GrayDark
        )
      },
      iconButton: {
        color: getThemeStyleColor(
          theme,
          StyleVariant.Primary,
          ThemeStyleColorVariant.GrayDark
        )
      }
    },
    MuiPickersDay: {
      day: {
        color: getThemeStyleColor(
          theme,
          StyleVariant.Primary,
          ThemeStyleColorVariant.GrayDark
        )
      },
      current: {
        color: getThemeStyleColor(
          theme,
          StyleVariant.Primary,
          ThemeStyleColorVariant.GrayDark
        ),
        ...borderMixin({
          color: getThemeStyleColor(
            theme,
            StyleVariant.Primary,
            ThemeStyleColorVariant.GrayDark
          ),
          side: {
            all: 1
          },
          style: "solid"
        })
      },
      daySelected: {
        ...borderMixin({
          side: {
            all: 0
          }
        })
      }
    }
  };
};
