import React from "react";
import { StyleVariant } from "@laba/ts-common";
import { PopupSizeVariant } from "components";
import { CancelIcon, CheckCircle } from "components/icons";
import { FCC } from "@laba/react-common";
import { Dialog } from "../Dialog/Dialog";

export enum AlertPopupVariant {
  Error = "Error",
  Success = "Success"
}

export interface AlertPopupProps {
  isMobile?: boolean;
  isOpen: boolean;
  onClose?: () => void;
  title?: string;
  subtitle?: string;
  alertPopupVariant?: AlertPopupVariant;
  primaryButtonText?: string;
  secondaryButtonText?: string;
  onPrimaryButtonClick?: () => void;
  onSecondaryButtonClick?: () => void;
  dialogSizeVariant?: PopupSizeVariant;
  loading?: boolean;
}

export const AlertPopup: FCC<AlertPopupProps> = ({
  isMobile,
  isOpen,
  onClose,
  title,
  subtitle,
  alertPopupVariant = AlertPopupVariant.Error,
  primaryButtonText,
  secondaryButtonText,
  onPrimaryButtonClick,
  onSecondaryButtonClick,
  dialogSizeVariant,
  children,
  loading
}) => {
  return (
    <Dialog
      open={isOpen}
      isMobile={isMobile}
      onPrimaryButtonClick={onPrimaryButtonClick ?? onClose}
      onSecondaryButtonClick={onSecondaryButtonClick ?? onClose}
      onClose={onClose}
      primaryButtonText={primaryButtonText}
      secondaryButtonText={secondaryButtonText}
      IconTitle={
        alertPopupVariant === AlertPopupVariant.Success
          ? CheckCircle
          : CancelIcon
      }
      textTitle={title}
      textSubtitle={subtitle}
      dialogSizeVariant={dialogSizeVariant}
      iconStyle={
        alertPopupVariant === AlertPopupVariant.Success
          ? StyleVariant.Success
          : StyleVariant.Danger
      }
      loading={loading}
    >
      {children}
    </Dialog>
  );
};
