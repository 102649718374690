import { Optional } from "@laba/ts-common";
import { head, isEqual } from "lodash-es";
import { getModelOrUndefined } from "model/primitives";
import { KnownScheduleType } from "model/resource/schedule";
import { KnownAppointmentType } from "model/resource";
import { getPublicLocationName } from "../publicLocation";
import { OrganizationPublicData } from "../publicOrganization";
import { PublicPractitionerData } from "../publicPractitioner";
import { PublicScheduleData } from "./publicSchedule";
import {
  PublicScheduleAvailabilityCalendar,
  PublicScheduleAvailabilityCalendarPayerListItem,
  PublicScheduleAvailabilityCalendarScheduleItem,
  ScheduleItemAvailabilityList
} from "./scheduleAvailabilityCalendar";

export const getPublicScheduleType = (
  publicSchedule?: PublicScheduleData
): Optional<KnownScheduleType> => {
  return publicSchedule?.type;
};

export const getPublicScheduleName = (
  publicSchedule?: PublicScheduleData
): Optional<string> => publicSchedule?.name;

export const getFirstPublicSchedulePractitioner = (
  publicSchedule?: PublicScheduleData
): Optional<PublicPractitionerData> =>
  getModelOrUndefined(head(publicSchedule?.performerList));

export const getPublicSchedulePhotoUrl = (
  publicSchedule?: PublicScheduleData
): Optional<string> => publicSchedule?.photo?.url;

export const getPublicScheduleLocationName = (
  publicSchedule?: PublicScheduleData
): Optional<string> => getPublicLocationName(publicSchedule?.location);

export const getPublicScheduleAvailabilityCalendarPayerListItemName = (
  publicScheduleAvailabilityCalendarPayerListItem?: PublicScheduleAvailabilityCalendarPayerListItem
): Optional<string> =>
  publicScheduleAvailabilityCalendarPayerListItem?.payer?.name;

export const getFirstCalendarScheduleItemFromPublicScheduleAvailabilityCalendar =
  (
    scheduleAvailabilityCalendar?: PublicScheduleAvailabilityCalendar
  ): Optional<PublicScheduleAvailabilityCalendarScheduleItem> =>
    head(scheduleAvailabilityCalendar?.scheduleList);

export const getFirstPublicScheduleAvailabilityCalendarScheduleItemAvailability =
  (
    publicScheduleAvailabilityCalendarScheduleItem?: PublicScheduleAvailabilityCalendarScheduleItem
  ): Optional<ScheduleItemAvailabilityList> =>
    head(publicScheduleAvailabilityCalendarScheduleItem?.availabilityList);

export const getOrganizationPublicDataModelFromSchedule = (
  publicSchedule?: PublicScheduleData
): Optional<OrganizationPublicData> =>
  getModelOrUndefined(publicSchedule?.organization);

export const getPhotoUrlFromPublicScheduleOrScheduleFirstPractitioner = (
  publicSchedule?: PublicScheduleData
): Optional<string> =>
  publicSchedule?.photo?.url ??
  getFirstPublicSchedulePractitioner(publicSchedule)?.profilePictureUrl;

export const getOrganizationPublicDataNotesFromSchedule = (
  publicSchedule?: PublicScheduleData
): Optional<string> =>
  getOrganizationPublicDataModelFromSchedule(publicSchedule)?.notes;

export const isPublicScheduleTypeAll = (
  publicSchedule?: PublicScheduleData
): Optional<boolean> => isEqual(publicSchedule?.type, KnownScheduleType.All);

export const getPublicAppointmentTypeFromScheduleType = (
  scheduleType?: KnownScheduleType
): KnownAppointmentType => {
  return scheduleType === KnownScheduleType.VideoChat
    ? KnownAppointmentType.VideoChat
    : KnownAppointmentType.Presential;
};

export const getPublicAppointmentTypeFromSchedule = (
  publicSchedule?: PublicScheduleData
): KnownAppointmentType => {
  return getPublicAppointmentTypeFromScheduleType(
    getPublicScheduleType(publicSchedule)
  );
};

export const getPublicSchedulePatientInstructions = (
  publicSchedule?: PublicScheduleData
): Optional<string> => {
  return publicSchedule?.patientInstruction;
};
